import React from "react";
import FrontHeader from "../../shared/FrontHeader/FrontHeader";
import FrontFooter from "../../shared/FrontFooter/FrontFooter";


function CGV() { 
    return(
        <div className="landing-page-component">
            <FrontHeader />
            <div className="page-content pb-5 pb-5">
                <h1 className="custom-page-title pt-5">
                    Politique Générale de Ventes <br /> de BAPOO
                </h1>
                <div className="container">
                    <div className="custom-politique-page-content pt-5">
                        <h3 className="custom-sm-title pb-1 pb-1">
                        Politique Générale de Ventes de BAPOO
                        </h3>
                        <div className="content-all-politique pt-4 pb-3">
                            <div className="content-politique-item pt-5">
                                <h3 className="custom-sm-title pb-2">
                                    1. Commandes et Paiements
                                </h3>
                                <div className="custom-sm-value">
                                    <div className="content-politique-item">
                                        <h3 className="custom-sm-title pb-1">
                                            1.1. Passer une Commande :
                                        </h3>
                                        <div className="custom-sm-value">
                                            Lorsque vous passez une commande sur notre site, vous acceptez d'acheter les produits ou services sélectionnés aux prix et conditions indiqués.
                                        </div>
                                    </div>
                                    <div className="content-politique-item pt-3">
                                        <h3 className="custom-sm-title pb-1">
                                            1.2. Paiements :
                                        </h3>
                                        <div className="custom-sm-value">
                                            Les paiements sont traités de manière sécurisée via des méthodes de paiement en ligne sécurisées. Les informations de paiement sont cryptées et protégées.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="content-politique-item pt-5">
                                <h3 className="custom-sm-title pb-4">
                                    2. Livraison et Frais de Port
                                </h3>
                                <div className="custom-sm-value">
                                    <div className="content-politique-item">
                                        <h3 className="custom-sm-title pb-1">
                                            2.1. Livraison : 
                                        </h3>
                                        <div className="custom-sm-value">
                                            Nous nous engageons à expédier vos commandes dans les délais indiqués sur notre site. Les délais de livraison peuvent varier en fonction de votre emplacement et du mode d'expédition choisi.
                                        </div>
                                    </div>
                                    <div className="content-politique-item pt-3">
                                        <h3 className="custom-sm-title pb-1">
                                            2.2. Frais de Port : 
                                        </h3>
                                        <div className="custom-sm-value">
                                            Les frais de port sont calculés en fonction du poids, de la taille et de la destination de votre commande. Les frais de port seront indiqués avant la livraison.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="content-politique-item pt-5">
                                <h3 className="custom-sm-title pb-4">
                                    3. Politique de Retour et de Remboursement
                                </h3>
                                <div className="custom-sm-value">
                                    <div className="content-politique-item">
                                        <h3 className="custom-sm-title pb-1">
                                            3.1. Retours : 
                                        </h3>
                                        <div className="custom-sm-value">
                                            BAPOO accepte le retour de tout produit non endommagé dans les <strong>15 jours</strong> suivant la date de réception de la commande et dans son emballage d’origine. 
                                            Pour ce faire, il est nécessaire de contacter au préalable l’entreprise dans les délais impartis. Au-delà, aucune demande de remboursement ne peut être acceptée. <br />

                                            Les produits devront être retournés uniquement à l’adresse à partir de laquelle ils ont été expédiés en France. Les frais d'expédition pour le retour 
                                            de votre article sont à votre charge. <br />

                                            Par ailleurs, tout article déjà utilisé, endommagé, sali ou incomplet ne pourra faire l’objet de retour.
                                        </div>
                                    </div>
                                    <div className="content-politique-item pt-3">
                                        <h3 className="custom-sm-title pb-1">
                                            3.2. Remboursements : 
                                        </h3>
                                        <div className="custom-sm-value">
                                            Une fois que votre retour est reçu et vérifié, nous vous enverrons un email pour vous aviser que nous avons reçu votre article retourné. Nous vous informerons également 
                                            de l'accord ou rejet de votre remboursement. <br />
                                            En cas d'accord sur le remboursement, un crédit sera appliqué à votre carte de crédit ou à la méthode originale de paiement. Les frais d'envoi lors de l'achat ne sont pas 
                                            remboursables. Si vous recevez un remboursement, le coût de l'expédition de l'envoi sera déduit de votre remboursement.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="content-politique-item pt-5">
                                <h3 className="custom-sm-title pb-2">
                                    4. Garantie
                                </h3>
                                <div className="custom-sm-value">
                                    Nous garantissons que nos produits sont exempts de défauts de matériaux et de fabrication. Cependant, la garantie varie suivant le produit commandé. Si un produit est défectueux, 
                                    veuillez nous contacter dans les délais annoncés plus haut pour obtenir un remplacement ou un remboursement.
                                </div>
                            </div>
                            <div className="content-politique-item pt-5">
                                <h3 className="custom-sm-title pb-2">
                                    5. Mentions légales
                                </h3>
                                <div className="custom-sm-value">
                                    Bapoo est une entreprise française enregistrée sous le numéro Siret 985 128 248 00012
                                </div>
                            </div>
                            <div className="content-politique-item pt-5">
                                <h3 className="custom-sm-title pb-4">
                                    6. Responsabilité
                                </h3>
                                <div className="custom-sm-value">
                                    Limitation de Responsabilité : Nous déclinons toute responsabilité pour tout dommage direct, indirect, spécial, consécutif ou punitif résultant 
                                    de l'utilisation de nos produits ou services. 
                                </div>
                            </div>
                            <div className="content-politique-item pt-5">
                                <h3 className="custom-sm-title pb-1">
                                    7. Modifications de la Politique
                                </h3>
                                <div className="custom-sm-value">
                                    Nous nous réservons le droit de modifier cette Politique à tout moment. Les modifications seront effectives dès leur publication sur notre site.
                                </div>
                            </div>
                            <div className="content-politique-item pt-5">
                                <h3 className="custom-sm-title pb-1">
                                    8. Contact
                                </h3>
                                <div className="custom-sm-value">
                                    Pour toute question concernant notre Politique Générale de Ventes, veuillez nous contacter via les coordonnées fournies sur notre site.
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
            <FrontFooter />
        </div>
    )
}

export default CGV